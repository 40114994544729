#header{
    position: fixed; /* Set the navbar to fixed position */
    top: 0px; /* Position the navbar at the top of the page */
    width: 100%; 
    height: 72px;
    background-color: var(--button-171);
    padding: 15px 40px;
    border-radius: 0px 0px 40px 40px;
    z-index: 9999;
}

.top_left_title {
    color: var(--color-f2);
    font-weight: normal;    
}

.bell{
    display: inline-block;
    vertical-align: middle;
    color: var(--color-f2);
    cursor: pointer;
}

.bell img {
    width: 24px;
    height: auto;
}

.circle{
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    color: var(--color-f2);
}
.circle img {
    width: 110px;
    height: auto;
}

@media screen and (max-width:1200px) {
    #header {
        padding: 15px 20px;
    }   
}

@media screen and (max-width:479px) {
    .circle {
        margin-left: 10px;
    }
    .circle img {
        width: 85px;
    }
    .bell img {
        width: 20px;
    }
}